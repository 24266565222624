body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(-45deg,#cc2b5e, #753a88, #ee7752, #e73c7e, #23a6d5, #23d5ab,#1565C0, #b92b27);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  /* background: #cc2b5e;  
  background: -webkit-linear-gradient(to right, #753a88, #cc2b5e);  
  background: linear-gradient(to right, #753a88, #cc2b5e);  */
  /* background: #b92b27;  
background: -webkit-linear-gradient(to right, #1565C0, #b92b27);  
background: linear-gradient(to right, #1565C0, #b92b27);  */

}
html, body {
  width: 100%;
  height:100%;
}
 
/* body {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}  */

@keyframes gradient { 
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
/* @keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
#animate-area {
  width: 1300px;
  height: 1300px;
  background-image: url(http://placekitten.com/400/200);
  background-position: 0px 0px;
  background-repeat: repeat-x;
  animation: animatedBackground 10s linear infinite alternate;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
