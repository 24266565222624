.App {
  text-align: center;
  margin: 70px;
  height: calc(100%-58px);
 
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: whitesmoke;
  
  /* background: #314755;  
background: -webkit-linear-gradient(to right, #26a0da, #314755);  
background: linear-gradient(to right, #26a0da, #314755);  */

}
.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color:#bdb2ff;
  opacity: 1.5;
  
  /* background: #cc2b5e;  
background: -webkit-linear-gradient(to right, #753a88, #cc2b5e);  
background: linear-gradient(to right, #753a88, #cc2b5e);  */

}
.App-Table{
background-color: white;
/* background: #77A1D3; 
background: -webkit-linear-gradient(to right, #E684AE, #79CBCA, #77A1D3); 
background: linear-gradient(to right, #E684AE, #79CBCA, #77A1D3);  */

}
.textErrors{
  font-style: inherit;
  font-weight: bold;
  color:red;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}
.backgroundColor {
  opacity: 1.5;
}
table tr:last-child th:first-child
{
  border-top-left-radius: 10px;
}
table tr:last-child th:last-child
{
  border-top-right-radius: 10px;
}
table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
table {
  border-collapse: collapse;
  border-radius: 10px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #666; /* this draws the table border  */
}

td {
  border: 0px solid #ccc;
}
/* .modal-contenido{
  background-color:aqua;
  width:300px;
  padding: 10px 20px;
  margin: 20% auto;
  position: relative;
}
.modal{
  background-color: rgba(0,0,0,.8);
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  opacity:0;
  pointer-events:none;
} */
/* form.example button {
  background: #2196f3;
  color: white;
  border: 1px solid grey;
  cursor: pointer;
}
form.example input[type="text"] {
  border: 1px solid grey;
  box-sizing: border-box;
  background: #f1f1f1;
}
form.example button:hover {
  background: #0b7dda;
} */
.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box div {
  width: 100px;
  height: 100px;
}
