.containerPrincipal {
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    border: 1px solid black;
    padding: 40px;
    background-color: white;
    border-collapse: collapse;
  border-radius: 10px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 3px #666; /* this draws the table border  */
}
.containerLogin{
    text-align: center;
}